 .is-sticky.bg-dark-1 {
     background-color: #051036;
 }

 .cruiseCard:hover .cardImage-slider__nav, .cruiseCard:hover .cardImage-slider__pagination {
     z-index: 9 !important;
 }

 .cardImage-slider__pagination {

     z-index: 9;
 }

 .cruiseCard .cardImage-slider__pagination .swiper-pagination-bullet-active {
     opacity: 1;
     z-index: 9;
     color: #fff;
 }



 .css-nby9nv-MuiStack-root>.MuiTextField-root, .css-nby9nv-MuiStack-root>.MuiPickersTextField-root {
     min-width: 0 !important;
 }

 .rs-input-group.rs-input-group-inside {
     border: 0 !important;
 }

 .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
     font-size: 13px !important;
     padding-left: 0 !important;
 }

 .MuiDateRangeCalendar-root.css-e47596-MuiDateRangeCalendar-root div:not(.MuiDateRangeCalendar-monthContainer) {
     /* display: none !important; */
     background-color: #000;
     top: -100px;
 }

 li a {
     color: inherit;

     text-decoration: none;
 }



 .citiesCard.-type-5 {
     color: #000;
 }

 .header-banner a:hover, footer a:hover, .header-banner a:focus, footer a:focus, .header-banner a:active, footer a:active {
     color: #F8D448 !important;
 }

 .searchMenu-loc__field .bg-white {
     height: auto;
     overflow: scroll;
 }

 /* .searchMenu-loc__field.-is-active {
     opacity: 1 !important;
     position: absolute;

 } */
 .searchMenu-loc__field.-is-active {
     pointer-events: auto;
     opacity: 1;
 }

 .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
     padding-left: 0;
 }

 .rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
     /* width: fit-content !important; */
 }



 .rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
     outline: unset !important;

 }

 .css-188mx6n-MuiSlider-root, .css-1228x3t {
     color: #BB4929 !important;
 }

 .rs-input-group .rs-form-control-wrapper~.rs-input-group-addon, .rs-input-group input.rs-input~.rs-input-group-addon {
     color: #BB4929;
 }

 .sidebar__item h5 {

     color: #BB4929 !important;
     font-weight: 600 !important;
 }

 .css-cp2j25-MuiSlider-thumb::before, .css-5s1qf1::before {
     background: #fff;
     border: 2px solid #BB4929;
 }

 .h-100 {
     height: 100%;
 }

 .fw-bold {
     font-weight: bold !important;
 }

 /* .masthead h1 {
     font-size: 5em;
     font-weight: bold;
     font-family: sans-serif;
     text-decoration: none;
     color: transparent;
     background: url("../public/wave.svg") repeat-x #fff;
     background-position-x: 0%;
     background-position-y: 0%;
     background-size: auto;
     background-clip: border-box;
     -webkit-background-clip: text;
     background-clip: text;
     background-size: 200% 100%;
     background-position-x: 0;
     background-position-y: 2em;
     -webkit-transition: background-position-y 0.6s ease;
     transition: background-position-y 0.6s ease;
     -webkit-animation: waveAnimation 4s infinite linear;
     animation: waveAnimation 4s infinite linear;
     animation-play-state: running;
     -webkit-animation-play-state: paused;
     animation-play-state: paused;
 } */

 /* .masthead:hover h1 {
     background-position-x: 0;
     background-position-y: 0;
     -webkit-animation-play-state: running;
     animation-play-state: running;
 } */

 /* .masthead p {
    
     font-weight: bold;
     font-family: sans-serif;
     text-decoration: none;
     color: transparent;
     background: url("../public/wave.svg") repeat-x #fff;
     background-position-x: 0%;
     background-position-y: 0%;
     background-size: auto;
     background-clip: border-box;
     -webkit-background-clip: text;
     background-clip: text;
     background-size: 200% 100%;
     background-position-x: 0;
     background-position-y: 2em;
     -webkit-transition: background-position-y 0.6s ease;
     transition: background-position-y 0.6s ease;
     -webkit-animation: waveAnimation 4s infinite linear;
     animation: waveAnimation 4s infinite linear;
     animation-play-state: running;
     -webkit-animation-play-state: paused;
     animation-play-state: paused;
 }

 .masthead:hover p {
     background-position-x: 0;
     background-position-y: 0;
     -webkit-animation-play-state: running;
     animation-play-state: running;
 } */


 @keyframes waveAnimation {
     from {
         background-position-x: 0%;
     }

     to {
         background-position-x: 200%;
     }
 }

 .gallery {
     height: 425px;
 }

 .fw-500 {
     font-weight: 500 !important;
     /* font-family: "Jost", sans-serif !important; */
 }

 .rs-drawer-header .rs-drawer-header-close {
     left: unset !important;
     right: 15px;
     color: #fff;
     background-color: red;

 }

 .rs-drawer-header .rs-drawer-header-close:hover {

     color: #e60000;
     background-color: #fcfcfc;

 }

 .room-content {
     padding: 20px 32px 7px;
     /* border: 1px solid rgba(30, 30, 30, 0.1); */
     border: transparent;
     background: #fff;
 }

 .btn {
     color: #fff;
     background-color: #e60000;
     padding: 2px !important;
     border-radius: 5px !important;
     line-height: 1;

     text-align: center;

 }

 .section-bg__item.overlay::after {
     position: absolute;
     content: "";
     width: 100%;
     height: 100%;
     background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
     left: 0;
     top: 0;
 }

 a:active, a:focus {
     color: #F8D448 !important;
 }

 .newsletter {

     /* background-attachment: fixed !important;
     background-image: url(https://cleoparksharm.com/static/media/footer-bg1.87614f0adaf90dd66ee7.jpg) !important;
     background-repeat: no-repeat !important;
     background-size: 100% 100%; */
     border-bottom: 1px dashed #ffffff66;
     /* padding-bottom: 30px; */

 }

 .last-footer {
     border-top: 1px dashed #ffffff66 !important;

 }

 footer>.container {
     position: relative;
     z-index: 1;
 }


 .book:hover .next {
     opacity: 0 !important;
 }

 /* .stf__block{
    width: 50% !;
 } */

 .home-section {
     position: absolute;

     left: 0;
     top: 0;
     background-repeat: no-repeat;
     background-size: cover;

     z-index: 1;
     background-position: center;
 }

 /* .effect-cls:after, .effect-cls:before,
 .effect-cls-up:after,
 .effect-cls-up:before {
     background: url('../public/effect.png');
     position: absolute;
     z-index: 99999999999999999999999999999;
 } */

 .effect-cls .cloud {
     -webkit-animation: slide 10s linear infinite;
     animation: slide 10s linear infinite;
     -webkit-transition: all 0.3s ease;
     transition: all 0.3s ease;
     z-index: 99999999999999999999999999999;
     height: 50px;
 }

 .animated-section .animation-section {
     opacity: 0.8;
 }

 /* datepacker  */
 .rs-picker-toggle-wrapper {
     width: 100%;
 }

 .rs-drawer-dialog {
     overflow-y: auto !important;
 }

 #included li {
     position: relative;
     list-style: none;
     padding-left: 30px;
     margin-bottom: 5px;
     margin-top: 0;
     padding-top: 0;
 }

 #included li::before {
     content: '\e938';
     font-family: icomoon !important;
     font-weight: 400;

     color: #008009;

     font-size: 10px;

     position: absolute;
     left: 0;
     top: 50%;
     transform: translateY(-50%);
 }

 #exclude li {
     position: relative;
     list-style: none;
     padding-left: 30px;
     margin-bottom: 5px;
     margin-top: 0;
     padding-top: 0;
 }

 #exclude li::before {
     content: "\e93e";
     font-family: icomoon !important;
     font-weight: 400;

     color: #D93025;

     font-size: 10px;

     position: absolute;
     left: 0;
     top: 50%;
     transform: translateY(-50%);
 }

 .singleMenu {
     opacity: 0;
 }

 .singleMenu.-is-active {
     opacity: 1;
     pointer-events: auto;
     z-index: 9999;
 }

 .rs-drawer-wrapper {
     z-index: 99999 !important;
 }

 sup {
     top: -1.6em !important;
 }

 .f54c1d70e0 {
     position: absolute;
     top: 15%;
     left: 7px;
     padding: 2px 5px 2px 10px;
     width: auto;
     height: auto;
     /* background: #F8D448; */
     background: #DD1225;
     /* border-radius: 4px 4px 4px 0; */
     /* box-shadow: 0 2px 8px 0 rgba(26, 26, 26, 0.16); */
     z-index: 5;
     text-align: center;
     font-size: 12px;
     line-height: 18px;
     font-weight: 700;
     /* color: #BB4929; */
     color: #fff;
     /* box-shadow: 1px 0px 2px #ccc; */
 }

 .f54c1d70e0:before {
     position: absolute;
     left: 0.5px;
     bottom: calc(-1* 8px);
     width: 0;
     height: 0;
     /* border: 4px solid #F8D448; */
     border: 4px solid #DD1225;
     border-bottom-color: transparent;
     border-left-color: transparent;
     content: "";
     z-index: -1;
     /* box-shadow: 1px 0px 4px #fff; */
 }

 .f54c1d70e0:after {
     position: absolute;
     top: 0.9px;
     left: 98%;
     background-color: #DD1225;
     content: "";
     height: 20.3px;
     width: 22px;
     display: block;
     transform: rotateZ(-90deg);
     clip-path: polygon(50% 0, 100% 0, 100% 41%, 50% 11%, 0 43%, 0 0);
 }

 .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover, .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover, .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover, .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content, .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
     opacity: 0.4;
 }

 .ctaCard {
     overflow: hidden !important;
 }

 .ctaCard:hover .ctaCard__content {
     top: 0% !important;
     transition: top 0.5s ease-in-out;
 }

 .ctaCard__content {
     top: 77% !important;
     transition: top 0.5s ease-in-out;
     text-align: center;

 }

 .ctaCard.-type-1 .ctaCard__content::before {

     background: linear-gradient(to bottom, transparent, rgba(5, 16, 54, 0.8), rgba(5, 16, 54, 0.7), rgba(5, 16, 54, 0.5)) !important;
     /* opacity: 0 !important; */
 }

 .rs-picker-default .rs-picker-toggle.rs-btn {
     border: 0 !important;
     outline: none !important;
 }

 .rs-picker-toggle-textbox, .rs-picker-default .rs-picker-toggle.rs-btn, .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
     color: #697488 !important;
     outline: none !important;
 }

 .rs-picker-toggle-indicator .rs-picker-caret-icon {
     color: #BB4929 !important;
 }

 .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
     background-color: #BB4929 !important;
 }

 .rs-calendar-table-cell-content:hover {
     background-color: #bb492960 !important;
     color: #ad2500 !important;
 }

 .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
     box-shadow: inset 0 0 0 1px #BB4929 !important;

 }