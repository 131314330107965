.loader-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-color: rgba(255, 255, 255, 0.9); */
	background-color: #fff;
	z-index: 9999;
}

.spinning-wheel {
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}