/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* Main */
[data-cue="zoomIn"], [data-cues="zoomIn"]>* {
  opacity: 1;
}

button[disabled], html input[disabled] {
  opacity: 0.5;
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 5px;
  transition: all 5s linear !important;
  border: 0 !important;
}

::-webkit-scrollbar:hover {
  width: 10px;


}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #13357B;
  border: 0 !important;

}

.dark::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #13357B;
  border: 0 !important;

}

::-webkit-scrollbar-track {
  background-color: transparent;

}

.curser-pointer {
  cursor: pointer;
}



@media screen and (min-width:767px) {
  .home-five p.section-desc2 {
    width: 50% !important;
  }
}

.text-main {
  color: #13357B;
}

a:hover {
  color: #13357B !important;
}

.z-9 {
  z-index: 9;
}

/* Bootstrap  */


/* Bootstrap Colors */
.text-primary {
  color: #BB4929 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

/* .text-white {
  color: #fff !important;
} */

.text-muted {
  color: #6c757d !important;
}

/* Background colors */
.bg-primary {
  background-color: #BB4929 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}



/* Button Colors */
.btn-primary {
  background-color: #BB4929 !important;
  color: #fff !important;
}

.btn-secondary {
  background-color: #6c757d !important;
  color: #fff !important;
}

.btn-success {
  background-color: #198754 !important;
  color: #fff !important;
}

.btn-danger {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.btn-warning {
  background-color: #ffc107 !important;
  color: #212529 !important;
}

.btn-info {
  background-color: #0dcaf0 !important;
  color: #212529 !important;
}

.btn-light {
  background-color: #f8f9fa !important;
  color: #212529 !important;
}

.btn-dark {
  background-color: #212529 !important;
  color: #fff !important;
}

.btn-white {
  background-color: #fff !important;
  color: #212529 !important;
}

/* Button Outline Colors */
.btn-outline-primary {
  color: #BB4929 !important;
  border-color: #BB4929 !important;
}

.btn-outline-secondary {
  color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-outline-success {
  color: #198754 !important;
  border-color: #198754 !important;
}

.btn-outline-danger {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-outline-warning {
  color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-outline-info {
  color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}

.btn-outline-light {
  color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.btn-outline-dark {
  color: #212529 !important;
  border-color: #212529 !important;
}